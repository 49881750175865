<template>
  <div id="periodEdit">
    <app-menu></app-menu>
    <div class="col-lg-10 offset-lg-1">
      <h3 class="text-center">Vytvořit Ročník</h3>
      <div class="row">
        <div class="col-lg-6">
          <form action="">
            <div class="form-group">
              <label>Vyberte stav ročníku:</label>
              <select class="form-control" v-model="period.state" id="exampleSelect1">
                <option value="ACTUAL">Aktuální</option>
                <option value="ARCHIVED">Archiv</option>
                <option value="CONCEPT">Koncept</option>
              </select>
            </div>

          </form>

        </div>
        <div class="col-lg-6">
          <form action="">
            <div class="form-group">
              <label for="formTicket">YouTube link:</label>
              <input type="text" class="form-control" id="formTicket" v-model="period.link"
                     placeholder="Odkaz na youtube video - stránka s programem">
            </div>
          </form>
        </div>
      </div>
      <div class="row pt-lg-4">
        <div class="col-lg-6">
          <h4 class="text-center">Čeština</h4>
          <form>
            <div class="form-group">
              <label for="formNameCz">Název:</label>
              <input type="text" class="form-control" id="formNameCz" v-model="period.name.cs"
                     placeholder="Název ročníku">
            </div>
            <div class="form-group">
              <label for="formNoteCz">Poznámka:</label>
              <input type="text" class="form-control" id="formNoteCz" v-model="period.note.cs" placeholder="Poznámka">
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <h4 class="text-center">Angličtina</h4>
          <form>
            <div class="form-group">
              <label for="formNameEn">Název:</label>
              <input type="text" class="form-control" id="formNameEn" v-model="period.name.en"
                     placeholder="Název ročníku">
            </div>
            <div class="form-group">
              <label for="formNoteEn">Poznámka:</label>
              <input type="text" class="form-control" id="formNoteEn" v-model="period.note.en" placeholder="Poznámka">
            </div>
          </form>
        </div>
      </div>
      <div class="text-center pt-lg-4">
        <send-button
            :send="create"
            text="Uložit Ročník"
            :response="error"
        />
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import axios from "@/api/axiom-instance";
import AdminMenu from "@/components/admin/AdminMenu";
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";

export default {
  name: "programPeriodEdit",
  components: {
    'app-menu': AdminMenu,
    'error-modal': ErrorModal,
    'send-button': SendButton
  },
  data() {
    return {
      period: {name: {}, note: {}, state: "ACTUAL"},
      error: null
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    create: function () {
      axios.post("/period", this.period).then(() => {
        this.$router.push({path: "/admin/program"})
      }).catch((error) => this.error = error)
    },
    load: function (id) {
      axios
          .get("/period", {params: {id: id}})
          .then((success) => {
            this.period = success.data;
          }).catch((error) => this.error = error)
    }
  }
}
</script>

<style scoped>
#periodEdit {
  background-color: white;
  height: 100vh;
}
</style>
